<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="component.name"
      :subtitle="$t('Materials')"
      :root="$t('Evaluation')"
      :root-location="businessDashLink('courses/' + this.uuid + '/evaluation/components')"
      icon="mdi-account-multiple"
      :side-navigation="EVALUATION_NAVIGATION"
      navigation-route="evaluation"
      :filters="filtersItems"
      :filters-data="$store.state.settings.filtersData"
      fill-height

      :no-content-condition="items.length === 0"
      :no-content-button-route="businessDashLink('courses/' + this.uuid + '/students')"
      no-content-button-text="Students"
      no-content-text="NoStudentsMessage"
      no-content-icon="mdi-account-multiple"
      :loading="loading"
  >

    <div class="d-flex justify-end align-center pr-4">
      <v-btn @click="exportData" class="noCaps" :color="wsACCENT" outlined>
        {{ $t('Download')}}
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </div>

    <v-sheet class="wsRoundedHalf fill-height ">
      <!-- Items Table-->
      <ws-data-table
          :items="itemsFiltered"
          :headers="headers"
          :search="$store.state.settings.filtersData.search"
          class="mt-6"
          @current-items="getExportData"
      >

        <template v-slot:item="{item}">
          <tr>
            <td>
              <div class="d-flex align-center text-no-wrap">
                <v-avatar class="mr-3"  :color="wsBACKGROUND"
                          size="40" key="profile_picture" >
                  <v-img v-if="item.img" :src="item.img" transition="xxx">
                    <template slot="placeholder">
                      <v-img src="@/assets/placeholder/user_blank.jpg"  transition="xxx" />
                    </template>
                  </v-img>
                  <v-icon  dark v-else>mdi-account-tie</v-icon>
                </v-avatar>
                <div>
                  <h5 :style="`color : ${wsACCENT}`">{{ item.name }} {{ item.firstname }}    </h5>
                  <h6
                      v-if="IS_BUSINESS_OWNER || !MODULE_PERMISSIONS(['MODULE_WESTUDY__HIDE_STUDENT_CONTACTS'], true)"
                      :style="`color : ${wsACCENT}`" > {{ item.email}}</h6>
                </div>
              </div>
            </td>

            <td width="50px" v-if="isTest">
              <div    v-if="item.test_tries > 0" class="d-flex">
                <ft-select
                    @input="openResetTestTries(item)"
                    :items="[{text : $t('ResetTestTries') , value : 'reset' }]"
                >
                  <v-btn text rounded :color="wsACCENT" class="noCaps pr-1" style="margin-top: 6px">
                    <h5 class="text-no-wrap text-center">{{ item.test_tries }} <span v-if="item.test_tries_total">  / {{ item.test_tries_total }}  </span></h5>
                    <v-icon>mdi-menu-down</v-icon>
                  </v-btn>
                </ft-select>
                <v-btn
                    @click="openStudentTest(item)"
                    text rounded :color="wsATTENTION" class="noCaps pr-1" style="margin-top: 6px">
                  <v-icon>mdi-eye</v-icon>
                  <h5>{{ $t('Preview') }}</h5>
                </v-btn>
              </div>

              <h5 class="text-no-wrap" v-else>{{ $t('NotStarted') }}</h5>




            </td>

            <td v-if="isEvaluated"  width="10px">
              <ws-text-field
                  width="120"
                  @change="changeEvaluation($event, item , 'result_result')"
                  :value="item.result_result"
                  number
                  number-length="3"
                  :placeholder="passPoints"
                  clearable
              >

              </ws-text-field>
            </td>
            <td width="10px" align="center">
              <div class="d-flex justify-end">
                <h5 v-if="item.result_result && !isEvaluated" class="mr-8">{{ item.result_result }}  </h5>
                <ft-select
                    @input="changeEvaluation($event, item , 'result_passed')" :items="passedSelect"
                    :value="item.result_passed"
                    :disabled="isTest"
                    fill-height
                >
                  <h5 :style="`color : ${item.result_passed ? wsACCENT : wsDARKLIGHT}`"
                      :class="isTest ? 'pr-6' : null"
                  >
                    {{ item.result_passed ? $t('Passed') : $t('NoResult')  }}
                  </h5>
                  <v-icon
                      v-if="!isTest"
                      :color="item.result_passed ? wsACCENT : wsDARKLIGHT"
                      class="mr-4"
                      small
                  >
                    mdi-menu-down
                  </v-icon>
                </ft-select>
              </div>
            </td>
            <td v-if="hasFiles" width="10px" align="center">
              <h5 class="text-no-wrap" v-if="!replyIsReady(item)" :style="`color : ${wsDARKLIGHT}`">
                <v-icon :color="wsDARKLIGHT">mdi-forum</v-icon>
                {{ $t('NoResult')}}
              </h5>
              <v-btn  @click="openSutdentReply(item)"   v-else :color="wsATTENTION" class="noCaps" text>
                <h5  class="text-no-wrap" :style="`color : ${wsATTENTION}`">
                  <v-icon :color="wsATTENTION">mdi-forum</v-icon>
                  {{ $t('ViewAnswer')}}
                </h5>
              </v-btn>
            </td>

          </tr>
        </template>

      </ws-data-table>
    </v-sheet>

    <!-- DIALOGS-->
    <ws-dialog
        v-model="displayResetTestTries"
        :title="$t('ResetTestTries')"
        :save-text="$t('Confirm')"
        @save="resetTestTries"
    >
      <h4 class="text-center mt-5">{{ $t('ResetTestTriesConfirmation') }}:</h4>
      <h3 class="text-center mt-6" :style="`color : ${wsACCENT}`">{{ selectedStudent.name }}</h3>
    </ws-dialog>

    <v-dialog v-model="displayImage"
              fullscreen>
      <v-sheet :color="wsBACKGROUND" class="fill-height">
        <div class="d-flex justify-space-between py-2 px-3 align-center">
          <h6>{{ selectedImage.name }}</h6>
          <div>
            <v-btn v-if="!SM" small :href="selectedImage.src" class="noCaps mr-3" :color="wsACCENT" outlined download type="attachment" target="_blank">
              <v-icon small>mdi-download</v-icon>
              {{$t('Download')}}
            </v-btn>
            <v-btn :small="SM" icon @click="displayImage = false"><v-icon :small="SM" >mdi-close</v-icon></v-btn>
          </div>
        </div>
        <v-btn v-if="SM" block small :href="selectedImage.src" class="noCaps mb-3" :color="wsACCENT" text download type="attachment" target="_blank">
          <v-icon small>mdi-download</v-icon>
          {{$t('Download')}}
        </v-btn>
        <v-img :src="selectedImage.src" transition="xxx" />
      </v-sheet>
    </v-dialog>


    <student-reply
        v-model="displayReplyDialog"
        :student-id="selectedStudentId"
        :component-id="componentId"
        @success="initPage(false)"
    />

    <test-preview-dialog
        v-if="displayTestResult"
        v-model="displayTestResult"
        :student-test-id="selectedStudentTest"
    />

  </sub-page>
</template>

<script>
import {mapActions, mapState} from "vuex";
import studentReply from "@/components/pages/westudy/admin/course/UI/studentReply";
import testPreviewDialog
  from "@/components/pages/westudy/admin/course/coureseEditor/compenents/lectureSections/testPreviewDialog";

export default {
  name: "evaluationComponentsStudents",
  components : {
    studentReply,
    testPreviewDialog
  },
  data() {
    return {
      isEvaluated : false,
      displayResetTestTries : false,
      passPoints : null,
      loading : false,

      isTest : false,
      testId : null,
      displayReplyDialog : false,
      selectedStudent : {},
      selectedStudentId : null,

      items : [],
      component : {},
      search : '',
      filter : {
        passed : false
      },
      itemsForExport : [],

      groups : [],
      enrollmentWavesSelect : [],
      paymentPlansSelect : [],

      displayImage : false,
      selectedImage : {},

      displayTestResult : false,
      selectedStudentTest : null
    }
  },
  props : {
    uuid : {
      type : String ,
      default : ''
    },
    componentId : {
      type : String ,
      default : ''
    }
  },
  computed : {
    ...mapState('courses',[ 'course', 'selectedLang' ]),

    hasFiles() {
      if ( this.component.task ) {
        if ( this.component.task.type !== 'test'  ) {
        return true
        }
      }
      return false
    },
    headers() {

      let items = [
        { text : this.$t('HumanName')  , value : 'name' },
        { text : this.$t('Completion') , value : 'result_passed' },
      ]

      if ( this.hasFiles) {
        items.push( { text : this.$t('Answers') , value : 'reply_files' } )
      }

      if ( this.isEvaluated ) {
        items.splice( 1,0, { text : this.$t('EvaluationPoints') , value : 'result_result' } )
      }
      if ( this.isTest) {
        items.splice( 1,0, { text : this.$t('TestTries') , value : 'test_tries' } )
      }

      return items
    },
    itemsFiltered() {
      let items = this.items
      if ( items.length === 0  ) {
        return items
      }

      let filters = this.$store.state.settings.filtersData

      if ( filters.passed === false ) {
          items = items.filter (  el => !el.result_passed )
        }

      if ( filters.passed === true ) {
        items = items.filter (  el => el.result_passed )
      }

      if ( filters.schedule ) {
        let filterValue = filters.schedule !== 'null' ? filters.schedule : null
        items = items.filter( el=> el.enrollment_wave_id === filterValue )
      }

      if ( filters.payment_plan ) {
        let filterValue = filters.payment_plan !== 'null' ? filters.payment_plan : null
        items = items.filter( el=> el.payment_plan_id === filterValue )
      }

      if ( filters.group ) {
        let filterValue = filters.group !== 'null' ? filters.group : null
        items = items.filter( el=> el.group_id === filterValue )
      }


      return items
    },
    passedSelect() {
      return [
        { text : this.$t('Passed') , value : true },
        { text : this.$t('NoResult') , value : false }
      ]
    },
    filtersItems() {
      let items = [];

      items = [
          { text : this.$t('Result') , value : 'passed' , type : 'select' , items : this.CHOICE_COMPLETION_SELECT}
      ]

      if (this.$store.state.courses.course.online) {
        let choiceItems  = JSON.parse(JSON.stringify(this.enrollmentWavesSelect))
        choiceItems.push({ text : this.$t('None') , value : 'null' })
        items.push( {text : this.$t('Schedule') , value : 'schedule' , type : 'select' , items : choiceItems } )
      }

      if (this.$store.state.courses.course.has_payment_plans) {
        let choiceItems  = JSON.parse(JSON.stringify(this.paymentPlansSelect))
        choiceItems.push({ text : this.$t('None') , value : 'null' })
        items.push( {text : this.$t('PaymentPlan') , value : 'payment_plan' , type : 'select' , items : choiceItems } )
      }

      if ( this.groupsFiltered.length > 0 ) {
        let choiceItems  = JSON.parse(JSON.stringify(this.groupsFiltered))
        choiceItems.push({ text : this.$t('None') , value : 'null' })
        items.push( {text : this.$t('Group') , value : 'group' , type : 'select' , items : choiceItems } )
      }

      return items
    },
    groupsFiltered() {
      if (this.groups.length === 0 ) {
        return []
      }
      if (!this.$store.state.courses.course.online) {
        return this.groups.filter(el=> !el.enrollment_wave_id )
      }
      if (this.$store.state.courses.course.online && this.$store.state.courses.course.online) {
        return this.groups.filter(el=> el.enrollment_wave_id )
      }

      return []

    }
  },
  watch : {
    'EVENTS_TRIGGER.STUDENT_REPLY_FILE'() {
      if (this.componentId === this.EVENT_ENTITY) {
        this.initPage()
      }
    },
  },
  methods : {
    ...mapActions('evaluation',[
        'GET_COMPONENT_STUDENTS_LIST',
        'EDIT_COMPONENT_EVALUATION' ,
        'ADD_EDIT_TEACHER_REPLY',
        'EXPORT_STUDENT_DATA',
        'RESET_STUDENT_TEST_TRIES'
    ]),
    ...mapActions('export',[
      'EXPORT_EVALUATION_STUDENT_DATA'
    ]),
    ...mapActions('upload', [
        'UPLOAD_STUDENT_REPLY',
        'GET_STUDENT_REPLY_FILE_FOR_TEACHER',
    ]),

    openStudentTest(item) {
      this.selectedStudentTest = item.student_test_id
      this.displayTestResult = true
    },

    openResetTestTries(item) {
      this.displayResetTestTries = true
      this.selectedStudent = item
    },
    async resetTestTries() {
      let data = {
        uuid : this.selectedStudent.uuid,
        test_id : this.testId,
        component_id : this.componentId
      }
      let result = await this.RESET_STUDENT_TEST_TRIES(data)
      if ( !result ) {
        return
      }

      this.selectedStudent.test_tries = 0
      this.selectedStudent.result_id = null
      this.selectedStudent.result_passed = null
      this.selectedStudent.result_result = null
      this.selectedStudent.result_status = null

      this.notify(this.$t('StudentTestTriesHasBeenReset'))
      this.displayResetTestTries = false
    },

    getExportData($event) {
      this.itemsForExport = $event
    },
    async exportData() {
      let data = {
        data : this.itemsForExport,
        component_id : this.componentId
      }
      let blob = await this.EXPORT_EVALUATION_STUDENT_DATA(data)
      if ( !blob ) {
        return
      }
      var url  = window.URL.createObjectURL(blob);
      var a = document.createElement('a');
      a.href = url;
      a.download = 'import_data.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();

      this.notify(this.$t('TableExported'))

    },

    resetStudentReply() {
      let index = this.items.findIndex( el => el.uuid === this.selectedStudent.uuid )
      if ( index === -1 ) {
        return null
      }
      this.items[index].task_reply_exist = false
      this.items = JSON.parse(JSON.stringify(this.items))
    },

    openSutdentReply(student) {
      this.selectedStudentId = student.uuid
      this.displayReplyDialog = true
    },

    replyIsReady(item) {
      if ( item.reply_files) {
        if ( item.reply_files.length > 0  ) {
          return true;
        }
      }
      if ( item.task_reply_exist) {
          return true;
      }
      return false
    },
    async changeEvaluation(value, item, field) {
      if ( field === 'result_result' ) {
        if ( !value ) {
          value = 0
        }
        if (value < 0) {
          value = 0
        }
        if (value > 100 ) {
          value = 100
        }
      }
      let data = {
        uuid : this.componentId,
        student_id : item.uuid,
        [field] : value
      }
      if ( item.result_id ) {
        data.result_id = item.result_id
      }
      let result = await this.EDIT_COMPONENT_EVALUATION(data)
      if ( !result ) { return }

      if ( !item.result_passed && result.passed && this.replyIsReady(item) ) {
        this.$store.state.courses.courseNewRepliesCounter--
      }

      if ( !item.result_id ) {
        item.result_id = result.uuid
        item.result_passed = result.passed
        this.items = JSON.parse(JSON.stringify(this.items))
      }
      item[field] = value
      item.result_passed = result.passed


      this.notify(this.$t('ChangesSaved'))
    },
    displayFiles(item) {

      if (item.expand === undefined) {
        item.expand = true
        item.isReset = false
        this.items = JSON.parse(JSON.stringify(this.items))
        return
      }

      item.expand = !item.expand

    },
    async openFile(file) {

      let data =  {
        file : file.uuid,
        course : this.uuid
      }
      let result = await this.GET_STUDENT_REPLY_FILE_FOR_TEACHER(data)
      if (!result) { return this.notify('File Not found') }

      this.entity = Object.assign({},this.entity)

      var a = document.createElement('a');
      a.href = result.url;
      a.download = result.name;
      if (result.mime.includes('image')) {
        this.displayImage = true
        this.selectedImage = {
          src : result.url,
          name : this.$t('StudentReply')
        }
      } else {
        a.setAttribute('download',result.name);
        document.body.appendChild(a);
        a.click();
        a.remove();
      }

    },
    async initPage(triggerLoading = true) {
      if ( triggerLoading ) {
        this.loading = true
      }

      let result = await this.GET_COMPONENT_STUDENTS_LIST(this.componentId)
      this.loading = false
      if ( !result || result === true) {
        return
      }

      this.items = result.students
      this.component = result.component
      this.groups = result.groups
      this.enrollmentWavesSelect = result.enrollment_waves
      this.paymentPlansSelect = result.payment_plans
      this.isTest = result.is_test
      this.testId = result.test_id
      this.isEvaluated = result.is_evaluated
      this.passPoints = result.pass_points

      this.itemsForExport = this.itemsFiltered

      if ( triggerLoading ) {
        this.$store.state.settings.filtersData.passed = this.isTest
        this.$store.state.settings.filtersData = Object.assign({} , this.$store.state.settings.filtersData )
      }


      if ( this.itemsFiltered.length === 0 && triggerLoading ) {
        this.$store.state.settings.filtersData.passed = null
        this.$store.state.settings.filtersData = Object.assign({} , this.$store.state.settings.filtersData )
      }

      this.READ_EVENTS({ type : 'student_reply_file', entity : this.componentId })
    }

  },
  mounted() {
   this.initPage()
  }
}
</script>

<style scoped>

</style>